import { Container } from "components/layout";
import Footer from "components/navigation/Footer";
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { groupBy, keys, set, sortBy } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { Organization } from "types/Organization";
import { makeApiRequest } from "utils/api";
import { useState } from "react";
import classNames from "classnames";
import {
  BuildingStorefrontIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ListBulletIcon,
} from "@heroicons/react/24/solid";
import { Event } from "types/Event";
import { Navbar } from "components/navigation/Navbar";
import { Button } from "components/buttons";
import { formatCurrency } from "utils/currency";
import { ClockIcon, MapPinIcon, XMarkIcon } from "@heroicons/react/24/outline";

export const ViewOrganization = ({}: {}) => {
  const [selectedView, setSelectedView] = useState<"calendar" | "list">("list");
  const params = useParams();
  const { data } = useQuery({
    queryKey: ["organization"],
    queryFn: () =>
      makeApiRequest({
        path: `/organizations/${params.id}`,
      }),
  });

  const organization: Organization = data?.data;

  const lightenColor = (hex: string, percent: number): string => {
    //using this for tab bg color
    // Remove the "#" if it exists
    const normalizedHex = hex.replace("#", "");
    const num = parseInt(normalizedHex, 16);
  
    let r = (num >> 16) + Math.round((255 - (num >> 16)) * (percent / 100));
    let g = ((num >> 8) & 0x00ff) + Math.round((255 - ((num >> 8) & 0x00ff)) * (percent / 100));
    let b = (num & 0x0000ff) + Math.round((255 - (num & 0x0000ff)) * (percent / 100));
  
    // Clamp RGB values between 0 and 255
    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));
  
    // Return as hex
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  };
  
  const primaryColor = organization?.primary_color || "#7209B7"; // Default fallback color
  const lightBackgroundColor = lightenColor(primaryColor, 75); // Lighten by 75%



  if (organization) {
    return (
      <>
        <Navbar />
        <Container className="mt-6">
          <div className="flex gap-3 items-center">
            {organization.image_url ? (
              <img src={organization.image_url} className="w-10 h-10" />
            ) : (
              <BuildingStorefrontIcon className="w-10 h-10 text-purple" />
            )}
            <div>
              <h1 
                className="text-3xl font-bold"
                style={{ color: organization.primary_color ? organization.primary_color : '#7208B7' }}
              >
                {organization.name}
              </h1>
            </div>
          </div>
        </Container>
        <Container className="mb-40">
          <h2 className="text-xl font-bold mb-5 py-5">Upcoming Events</h2>

          <div className="mb-10">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                onChange={(e) => setSelectedView(e.target.value as any)}
                value={selectedView}
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-purple-500 focus:ring-purple-500"
                defaultValue={selectedView}
              >
                <option value="list">List</option>
                <option value="calendar">Calendar</option>
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="flex space-x-4" aria-label="Tabs">
                <span
                  onClick={() => setSelectedView("list")}
                  role="button"
                  aria-current={selectedView === "list" ? "page" : undefined}
                  className={classNames(
                  selectedView === "list"
                    ? "rounded-md px-3 py-2 text-sm font-medium flex gap-1"
                    : "text-gray-500 hover:text-gray-700 rounded-md px-3 py-2 text-sm font-medium flex gap-1"
                  )}
                  style={
                  selectedView === "list"
                    ? {
                      backgroundColor: lightBackgroundColor, 
                      color: primaryColor, 
                      borderColor: primaryColor, 
                    }
                    : {}
                  }
                >
                  <ListBulletIcon className="w-5" /> List
                </span>

                <span
                  onClick={() => setSelectedView("calendar")}
                  role="button"
                  aria-current={
                    selectedView === "calendar" ? "page" : undefined
                  }
                  className={classNames(
                    selectedView === "calendar"
                      ? "rounded-md px-3 py-2 text-sm font-medium flex gap-1"
                      : "text-gray-500 hover:text-gray-700 rounded-md px-3 py-2 text-sm font-medium flex gap-1"
                    )}
                    style={
                    selectedView === "calendar"
                      ? {
                        backgroundColor: lightBackgroundColor, 
                        color: primaryColor, 
                        borderColor: primaryColor, 
                      }
                      : {}
                    }
                >
                  <CalendarIcon className="w-5" /> Calendar
                </span>
              </nav>
            </div>
          </div>
          {selectedView === "list" && <ListView events={organization.events} />}
          {selectedView === "calendar" && <CalendarView organization={organization} />}
        </Container>
      </>
    );
  }
  return null;
};

export const ListView = ({ events }: { events: Event[] }) => {
  const sorted = sortBy(events, "start_date");

  if (!events || events.length === 0) {
    return (
      <div className="w-full shadow-lg text-center bg-white p-6 border border-gray-200 rounded-lg">
        <CalendarDaysIcon className="mx-auto w-16 text-purple" />
        <h3 className="text-2xl font-bold text-gray-600">No Upcoming Events</h3>
        <p className="text-gray-600 mt-2">
          It looks like there aren’t any events listed right now. Check back soon for new events!
        </p>
      </div>
    );
  }

  return (
    <div>
      {sorted.map((event) => {
        return (
          <div
            key={event.id} // Ensure you use a unique key for each item
            className="flex justify-between items-center w-full border-b border-gray-400 py-3"
          >
            <img
              src={
                event.image_url ||
                "https://lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-black.svg"
              }
              className="md:block w-[90px] h-[90px] object-cover flex-shrink-0 rounded-full border-4"
              style={{
                borderColor: event.organization.primary_color || "#7209B7",
              }}
              alt={event.name}
            />

            <div className="flex-grow flex justify-between items-center ml-3">
              <div className="flex-grow">
                <span className="font-bold md:text-xl">{event.name}</span>
                <br />
                <CalendarIcon className="w-4 inline-block mr-1 mb-1" />
                <span
                  className="font-bold text-sm mr-1"
                  style={{
                    color: event.organization.secondary_color
                      ? event.organization.secondary_color
                      : "#F72585",
                  }}
                >
                  {format(new Date(event.start_date), "E, MMM do Y")}
                </span>
                <span className="text-gray-700 text-sm">
                  @ {format(new Date(event.start_date), "h:mm a")}
                </span>
                <br />
                <MapPinIcon className="w-4 inline-block mr-1 mb-1" />
                <span className="text-sm">{event.primary_venue?.name}</span>
              </div>

              <Link
                to={`/events/${event.slug}`}
                className={`
                  text-center bg-transparent px-5 py-2 border rounded-full font-bold 
                  hover:brightness-110 
                  ${
                    event.organization.primary_color
                      ? ""
                      : "text-purple border-purple hover:bg-purple hover:text-white"
                  }
                `}
                style={
                  event.organization.primary_color
                    ? {
                        color: event.organization.primary_color,
                        borderColor: event.organization.primary_color,
                      }
                    : undefined
                }
                onMouseEnter={(e) => {
                  if (event.organization.primary_color) {
                    e.currentTarget.style.backgroundColor =
                      event.organization.primary_color;
                    e.currentTarget.style.color = "#ffffff";
                  }
                }}
                onMouseLeave={(e) => {
                  if (event.organization.primary_color) {
                    e.currentTarget.style.backgroundColor = "transparent";
                    e.currentTarget.style.color =
                      event.organization.primary_color;
                  }
                }}
              >
                View <span className="hidden md:inline">Tickets</span>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};


export const CalendarView = ({ organization }: { organization: Organization }) => {
  const [showEventDetails, setShowEventDetails] = useState<Event | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const params = useParams();
  const { data } = useQuery({
    queryKey: ["event_calendar", selectedMonth, params.id],
    queryFn: () =>
      makeApiRequest({
        path: "/event_calendar",
        params: {
          organization_id: params.id,
          month: selectedMonth,
        },
      }),
    enabled: !!selectedMonth && !!params.id,
  });

  const events: Event[] = data?.data;

  const monthStart = startOfMonth(selectedMonth);
  const monthEnd = endOfMonth(selectedMonth);
  const days = eachDayOfInterval({
    start: startOfWeek(monthStart),
    end: endOfWeek(monthEnd),
  });

  const nextMonth = () => {
    setSelectedMonth((prev) => {
      return new Date(prev.getFullYear(), prev.getMonth() + 1);
    });
  };

  const prevMonth = () => {
    setSelectedMonth((prev) => {
      return new Date(prev.getFullYear(), prev.getMonth() - 1);
    });
  };

  const groupedEvents = groupBy(events, (e) =>
    format(new Date(e.start_date), "MMMM d, yyyy")
  );

  return (
    <>
      <div className="flex gap-3 w-full justify-center items-center my-4">
        <button onClick={prevMonth}>
          <ChevronDoubleLeftIcon className="w-4" />
        </button>
        <span className="font-bold">{format(selectedMonth, "MMMM yyyy")}</span>
        <button onClick={nextMonth}>
          <ChevronDoubleRightIcon className="w-4" />
        </button>
      </div>
      <div className="sm:hidden">
        {keys(groupedEvents).map((date) => {
          const dateEvents = groupedEvents[date];
          return (
            <div
              key={date}
              className="mt-4 w-full border-b border-gray-300 py-6 px-3"
            >
              <h2 className="text-xl font-bold flex gap-2">
                <CalendarDaysIcon className="w-6" />
                <span>{format(new Date(date), "EEEE, MMMM d, yyyy")}</span>
              </h2>
              {dateEvents?.map((event) => (
                <div
                  key={event.id}
                  className="flex flex-col gap-2 font-bold mt-3"
                  style={{ color: organization.secondary_color ? organization.secondary_color : "#7209B7" }}
                >
                  <div className="relative">
                    <img
                      src={event.image_url}
                      className="w-full h-auto rounded-lg"
                    />
                    <div className="text-lg w-full bg-black/60 absolute bottom-0 px-2 py-4 block leading-6 rounded-b-lg">
                      <span className="text-white">

                        {event.name}
                        <br />
                        <span className="text-sm">
                          <ClockIcon className="w-4 inline-block mr-1 mb-1" />
                          {format(new Date(event.start_date), "E, MMM do Y @ h:mm a")}

                          <br />
                          <MapPinIcon className="w-4 inline-block mr-1 mb-1" />
                          {event.primary_venue?.name}
                        </span>
                      </span>
                      <br />
                      {event.open_to_public ? (
                        <Link to={`/events/${event.slug}`}>
                          <Button
                            className="w-full mt-4 rounded-lg"
                            style={
                              organization.primary_color
                                ? {
                                    backgroundColor: organization.primary_color,
                                    borderColor: organization.primary_color,
                                    color: "#ffffff",
                                  }
                                : undefined
                            }
                          >
                            Buy Tickets
                          </Button>
                        </Link>
                      ) : (
                        <div className="text-center mt-4 text-red-500 font-bold">
                          Event is closed
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      <div className="hidden sm:block">
        <div className="flex gap-0 mt-6">
          <span 
            className="w-[14%] h-10 items-center text-center text-sm font-bold border-l border-t flex justify-center text-white"
            style={{backgroundColor: organization.secondary_color ? organization.secondary_color : '#7209B7'}}
          >
            Sun
          </span>
          <span 
            className="w-[14%] h-10 items-center text-center text-sm font-bold border-t flex justify-center text-white"
            style={{backgroundColor: organization.secondary_color ? organization.secondary_color : '#7209B7'}}
          >
            Mon
          </span>
          <span 
            className="w-[14%] h-10 items-center text-center text-sm font-bold border-t flex justify-center text-white"
            style={{backgroundColor: organization.secondary_color ? organization.secondary_color : '#7209B7'}}
          >
            Tue
          </span>
          <span 
            className="w-[14%] h-10 items-center text-center text-sm font-bold border-t flex justify-center text-white"
            style={{backgroundColor: organization.secondary_color ? organization.secondary_color : '#7209B7'}}
          >
            Wed
          </span>
          <span 
            className="w-[14%] h-10 items-center text-center text-sm font-bold border-t flex justify-center text-white"
            style={{backgroundColor: organization.secondary_color ? organization.secondary_color : '#7209B7'}}
          >
            Thu
          </span>
          <span 
            className="w-[14%] h-10 items-center text-center text-sm font-bold border-t flex justify-center text-white"
            style={{backgroundColor: organization.secondary_color ? organization.secondary_color : '#7209B7'}}
          >
            Fri
          </span>
          <span 
            className="w-[14%] h-10 items-center text-center text-sm font-bold border-r border-t flex justify-center text-white"
            style={{backgroundColor: organization.secondary_color ? organization.secondary_color : '#7209B7'}}
          >
            Sat
          </span>
        </div>
        <div className="flex gap-0 flex-wrap">
          {days.map((day) => {
            const dayEvents = events?.filter((event) => {
              return (
                format(new Date(event.start_date), "yyyy-MM-dd") ===
                format(day, "yyyy-MM-dd")
              );
            });

            const isLastMonth = day.getMonth() !== selectedMonth.getMonth();
            const isMonOrSun = day.getDay() === 0 || day.getDay() === 1;

            return (
              <div
                key={day.getTime()}
                className={classNames(
                  "w-[14%] min-h-[100px] border p-2 border-gray-300 relative pt-6",
                  isLastMonth && "bg-gray-100"
                )}
              >
                <span
                  className={classNames(
                    "text-xs absolute top-1 left-1",
                    isLastMonth && "text-gray-400"
                  )}
                >
                  <span>{format(day, "do")}</span>
                </span>
                {dayEvents?.map((event) => (
                  <div className="flex gap-1 relative">
                    {event.id === showEventDetails?.id && (
                      <div
                        className={classNames(
                          `absolute top-0 w-60 rounded-lg shadow-lg bg-white border border-gray-500 z-10 p-4`,
                          isMonOrSun ? "left-full" : "right-full"
                        )}
                      >
                        <button
                          onClick={() => setShowEventDetails(null)}
                          className="absolute top-1 right-1 text-xs"
                        >
                          <XMarkIcon className="w-3" />
                        </button>
                        <img src={event.image_url} className="w-full h-24" />
                        <h4 className="text-md font-bold my-1 text-center">{event.name}</h4>
                        <span className="text-xs text-gray-500 block text-center">
                          <CalendarIcon className="w-4 mr-1 mb-1 inline-block" />
                          {format(
                            new Date(event.start_date),
                            "E, MMM do Y @ h:mm a"
                          )}
                        </span>
                        <span className="text-xs text-gray-500 block mt-1 text-center">
                          <MapPinIcon className="w-4 mr-1 mb-1 inline-block" />
                          {event.primary_venue?.name}

                        </span>
                        <div className="text-center mt-2">
                          From:{" "}
                          <span style={{ color: organization.secondary_color ? organization.secondary_color : "#F72585" }}>
                            {formatCurrency(event.lowest_price)}
                          </span>
                        </div>
                        {event.open_to_public ? (
                        <Link to={`/events/${event.slug}`}>
                          <Button
                            className="w-full mt-4 rounded-lg"
                            style={
                              organization.primary_color
                                ? {
                                    backgroundColor: organization.primary_color,
                                    borderColor: organization.primary_color,
                                    color: "#ffffff",
                                  }
                                : undefined
                            }
                          >
                            Buy Tickets
                          </Button>
                        </Link>
                      ) : (
                        <div className="text-center mt-4 text-red-500 font-bold">
                          Event is closed
                        </div>
                      )}
                      </div>
                    )}
                    <span 
                      className="p-1 rounded-full w-1 block h-1 mt-1"
                      style={{ backgroundColor: organization.primary_color ? organization.primary_color : '#7209B7' }}
                    
                    />
                    <button
                      onClick={() => setShowEventDetails(event)}
                      className="text-left text-xs font-semibold leading-4 block"
                      style={{ color: organization.primary_color ? organization.primary_color : '#7209B7' }}
                    >
                      {event.name}
                    </button>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
