import { Navbar } from "components/navigation/Navbar";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest, RequestProps } from "utils/api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Container } from "components/layout";
import { Helmet } from "react-helmet";
import { sanitizeHTML } from "utils/sanitizeHTML";
import { useState, useEffect } from "react";
import { VendorForm } from "types/VendorForm";
import { FormField } from "types/FormField";
import { useAuth } from "providers/AuthContext";
import { useNotification } from "providers/NotificationContext";
import { Button } from "components/buttons";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";

export const ViewVendorForm = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { showSuccess, showError } = useNotification();
  const navigate = useNavigate();

  const eventRequestParams: RequestProps = {
    path: `/events/${id}`,
    params: { vendor_form_page: true },
  };

  const { data: eventData, isLoading: isEventLoading, error: eventError } = useQuery({
    queryKey: ["event", id],
    queryFn: () => makeApiRequest(eventRequestParams),
    refetchOnWindowFocus: false,
  });

  const event = eventData?.data;

  const vendorFormRequestParams: RequestProps | null = event
    ? {
        path: `/vendor_forms/${event.vendor_form_id}`,
        params: { event_id: event.id },
      }
    : null;

  const { data: vendorFormData, isLoading: isVendorFormLoading, error: vendorFormError } = useQuery({
    queryKey: ["vendorForm", event?.vendor_form_id],
    queryFn: () => vendorFormRequestParams && makeApiRequest(vendorFormRequestParams),
    enabled: !!vendorFormRequestParams,
    refetchOnWindowFocus: false,
  });

  const vendorForm: VendorForm = vendorFormData?.data;

  const incompleteFormSubmission =
    user?.incomplete_event_form_submission?.event_id === event?.id
      ? user?.incomplete_event_form_submission
      : null;

  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, boolean>>({});

  const mapAnswersToFormValues = (answers: any[]) => {
    return answers.reduce((acc, answer) => {
      const { form_field_id, form_field_option_id, content } = answer;
  
      // Assign value based on field type
      acc[form_field_id] =
        form_field_option_id ??
        (content === "true" ? true : content === "false" ? false : content) ??
        "";
  
      return acc;
    }, {});
  };
  
  
  

  useEffect(() => {
    if (incompleteFormSubmission && Object.keys(formValues).length === 0) {
      const preloadedValues = mapAnswersToFormValues(incompleteFormSubmission.answers || []);
      const sanitizedValues = Object.keys(preloadedValues).reduce((acc: Record<string, any>, key: string) => {
        acc[key] = preloadedValues[key] ?? ""; // Replace null or undefined with ""
        return acc;
      }, {});
      setFormValues(sanitizedValues);
    }
  }, [incompleteFormSubmission]);

  const handleInputChange = (fieldId: string, value: any) => {
  setFormValues((prevValues) => ({ ...prevValues, [fieldId]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [fieldId]: false }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    const answers = Object.entries(formValues).map(([fieldId, value]) => {
      const formField = vendorForm?.form_fields.find((field) => field.id.toString() === fieldId);
      const existingAnswer = incompleteFormSubmission?.answers.find(
        (answer) => answer.form_field_id === parseInt(fieldId, 10)
      );
  
      return {
        id: existingAnswer?.id || null,
        form_field_id: parseInt(fieldId, 10),
        vendor_form_id: vendorForm?.id,
        form_field_option_id:
          formField?.field_type === "radio" || formField?.field_type === "select"
            ? Number(value)
            : null,
        content:
          formField?.field_type === "checkbox"
            ? (value ? "true" : "false")
            : formField?.field_type === "radio" || formField?.field_type === "select"
            ? formField.options.find((opt) => opt.id === Number(value))?.content || ""
            : value,
        form_field_content: formField?.content,
        user_id: user?.id,
      };
    });
  
    const payload = {
      form_submission: {
        vendor_form_id: vendorForm?.id,
        event_id: event?.id,
        organization_id: event?.organization_id,
        user_id: user?.id,
        answers_attributes: answers,
      },
    };
  
    try {
      if (incompleteFormSubmission) {
        const updatedSubmission = await makeApiRequest({
          path: `/events/${id}/form_submissions/${incompleteFormSubmission.id}`,
          method: "PATCH",
          params: payload,
        });
        const updatedValues = mapAnswersToFormValues(updatedSubmission.data.answers);
        setFormValues(updatedValues);
        showSuccess("Form updated successfully!");
      } else {
        await makeApiRequest({
          path: `/events/${id}/form_submissions`,
          method: "POST",
          params: payload,
        });
        showSuccess("Form submitted successfully!");
      }
      navigate("/checkout");
    } catch (error) {
      const response = (error as any).response?.data;
  
      let errorMessage =
        "Failed to submit the form. Please fill out all required fields and try again.";
      if (response?.errors) {
        if (Array.isArray(response.errors)) {
          errorMessage = response.errors.join(", ");
        } else if (typeof response.errors === "string") {
          errorMessage = response.errors;
        }
      }
  
      showError(errorMessage);
  
      const missingFields = vendorForm?.form_fields.filter((field) => {
        const value = formValues[field.id];
        if (field.required) {
          if (field.field_type === "checkbox") {
            return value !== true; // Checkboxes must be checked if required
          }
          return !value; // Other fields must not be empty or undefined
        }
        return false;
      });
  
      if (missingFields.length > 0) {
        const errors = missingFields.reduce((acc: Record<string, boolean>, field) => {
          acc[field.id] = true;
          return acc;
        }, {});
        setValidationErrors(errors);
        showError("Please fill out all required fields.");
        setIsSubmitting(false);
        return;
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  

  if (isEventLoading || isVendorFormLoading) {
    return (
      <Container>
        <div className="flex justify-center items-center h-screen">
          <p>Loading...</p>
        </div>
      </Container>
    );
  }

  if (eventError || vendorFormError) {
    return (
      <Container>
        <div className="flex justify-center items-center h-screen">
          <p>
            {eventError
              ? "Failed to load event data."
              : "Failed to load vendor form data. Please try again."}
          </p>
        </div>
      </Container>
    );
  }

  return (
    <>
      <Navbar />
      <Container>
        <Helmet>
          <title>{vendorForm?.name || "Vendor Form"}</title>
        </Helmet>
        <div className="mt-8 max-w-3xl mx-auto mb-48">
          <div className="my-5">
            <Link
              className="flex items-center w-36"
              style={{
                color: event.organization.secondary_color
                  ? event.organization.secondary_color
                  : "#F72585",
              }}
              to={`/events/${event.slug}`
              }
            >
              <ChevronDoubleLeftIcon className="w-4" />{" "}
              Back to Event
            </Link>
          </div>
          <h1 className="text-2xl font-bold mb-6">{vendorForm?.name}</h1>
          <form onSubmit={handleSubmit} className="space-y-4 p-5 shadow-md border rounded-lg">
            {vendorForm?.form_fields?.map((field: FormField) => (
              <div
                key={field.id}
                className={`p-8 border-b ${
                  validationErrors[field.id] ? "border-red-500" : ""
                }`}
              >
                {field.field_type === "html" ? (
                  <div
                    className="prose"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(field.content),
                    }}
                  ></div>
                ) : (
                  <>
                    {field.field_type === "checkbox" ? (
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id={`checkbox-${field.id}`}
                          checked={!!formValues[field.id]}
                          onChange={(e) => handleInputChange(field.id, e.target.checked)}
                        />
                        <label htmlFor={`checkbox-${field.id}`} className="text-sm font-medium">
                          {field.content}
                          {field.required && <span className="text-red-500">*</span>}
                        </label>
                      </div>
                    ) : (
                      <>
                        <label className="block text-sm font-medium mb-2">
                          {field.content}
                          {field.required && <span className="text-red-500">*</span>}
                        </label>
                        {renderFormField(field, formValues, handleInputChange)}
                      </>
                    )}

                  </>
                )}
              </div>
            ))}
            <div className="flex justify-end">
              <Button className="mt-6" type="submit" disabled={isSubmitting} variant="primary">
                {isSubmitting
                  ? "Submitting..."
                  : incompleteFormSubmission
                  ? "Continue to Checkout"
                  : "Continue to Checkout"}
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </>
  );
};

const renderFormField = (
  field: FormField,
  formValues: Record<string, any>,
  handleInputChange: (fieldId: string, value: any) => void
) => {
  const value = formValues[field.id];

  const commonProps = {
    id: field.id,
    name: field.id,
    required: field.required,
    value: value !== null && value !== undefined ? value : "",
    onChange: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) =>
      handleInputChange(field.id, e.target.value),
  };
  
  

  switch (field.field_type) {
    case "text":
      return <input type="text" {...commonProps} className="block w-full border p-2 rounded" />;
    case "textarea":
      return <textarea {...commonProps} className="block w-full border p-2 rounded" />;
    case "radio":
      return (
        <div>
          {field.options.map((option) => (
            <label key={option.id} className="flex items-center space-x-2">
              <input
                type="radio"
                id={`${field.id}-${option.id}`}
                name={field.id}
                value={option.id}
                checked={String(option.id) === String(value)}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
              />
              <span>{option.content}</span>
            </label>
          ))}
        </div>
      );
    case "checkbox":
      return (
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id={`checkbox-${field.id}`}
            checked={!!formValues[field.id]} // Ensure proper boolean value
            onChange={(e) => handleInputChange(field.id, e.target.checked)}
          />
          <label htmlFor={`checkbox-${field.id}`} className="text-sm font-medium">
            {field.content}
          </label>
        </div>
      );
      
      
    case "select":
      return (
        <select {...commonProps} className="block w-full border p-2 rounded">
          <option value="">Select an option</option>
          {field.options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.content}
            </option>
          ))}
        </select>
      );
    case "date":
      return <input type="date" className="border p-2 w-full rounded" />;
    case "time":
      return <input type="time" className="border p-2 w-full rounded" />;
    case "number":
      return <input type="number" className="border p-2 w-full rounded" />;
    default:
      return null;
  }
};
