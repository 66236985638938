import { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "providers/AuthContext";

const navigation = [
  { name: "Find Tickets", href: "/explore" },
  { name: "How It Works", href: "/sell-on-happy" },
  { name: "Pricing", href: "/pricing" },
  { name: 'Contact', href: '/contact' },
  // { name: "Our Company", href: "#" },
];

export const LandingNav = ({}: {}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const scrollToHowItWorks = () => {
    navigate("/sell-on-happy");
    setTimeout(() => {
      const howItWorks = document.getElementById("how-it-works");
      if (howItWorks) {
        howItWorks.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }

  return (
    <div className="px-6 py-6 lg:px-8 absolute w-full top-0 z-50">
      <nav className="flex items-center justify-between" aria-label="Global">
        <div className="flex lg:flex-1">
          <button
            onClick={() => navigate("/")}
            className="-m-1.5 p-1.5 flex items-center"
          >
            <img
              alt="Happy Guy"
              className="w-6 mr-3 md:w-8"
              src={
                "//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
              }
            />
            <div>
              <h2 className="text-xl font-milkshake text-purple">Happy Ticketing</h2>
            </div>
          </button>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-purple"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="text-sm font-semibold leading-6 text-gray-700 p-3 rounded-lg hover:bg-white/30"
            >
              {item.name}
            </Link>
          ))}
          {/* <button
            onClick={scrollToHowItWorks}
            className="text-xs font-semibold leading-6 text-gray-700 p-3 rounded-lg hover:bg-white/30"
          >
            How It Works
          </button> */}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {user ? (
            <Link
              to="/dashboard"
              className="text-sm font-semibold leading-6 text-pink p-3 rounded-lg hover:bg-white/30"
            >
              Account
            </Link>
          ) : (
            <Link
              to="/login"
              className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-pink hover:bg-white/30"
            >
              Log In / Register <span aria-hidden="true">&rarr;</span>
            </Link>
          )}
        </div>
      </nav>
      <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <DialogPanel className="fixed inset-0 z-50 overflow-y-auto bg-white px-6 py-6 lg:hidden">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="text-purple font-milkshake text-lg">Happy Ticketing</span>
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-pink"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-purple hover:bg-gray-400/10"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                {user ? (
                  <Link
                    to="/account"
                    className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-purple hover:bg-gray-400/10"
                  >
                    My Account
                  </Link>
                ) : (
                  <Link
                    to="/login"
                    className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-purple hover:bg-gray-400/10"
                  >
                    Log in / Sign up
                  </Link>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </div>
  );
};
