import { Navbar } from "./navigation/Navbar";

export const PartnerAgreement = ({}: {}) => {
  return (
    <>
      <div className="container mx-auto p-6">
        <div className="py-10">
          <h1 className="text-center text-xl font-bold">
            Partner Agreement for Happy Ticketing LLC
          </h1>
          <span className="text-sm mx-auto block w-fit text-gray-500">
            Effective Date: January 8, 2025
          </span>
        </div>

        <p className="mb-4">
          This Partnership Agreement (the "Agreement") is entered into by and
          between Happy Ticketing LLC ("Company") and the Partner ("Partner")
          upon acceptance of this Agreement by the Partner through the
          application process on the Company’s website. By participating in the
          Partner Program, the Partner agrees to the following terms and
          conditions:
        </p>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            1. Introduction and Purpose
          </h2>
          <p>
            The purpose of this Agreement is to establish the terms under which
            the Partner will participate in the Company’s Partner Program.
            Partners will promote the Company’s ticketing platform and be
            compensated for bringing new vendors to the platform who generate
            ticket sales.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">2. Definitions</h2>
          <ul className="list-disc pl-6">
            <li>
              <strong>Partner:</strong> An individual or entity that has agreed
              to the terms of this Agreement and is approved to participate in
              the Partner Program.
            </li>
            <li>
              <strong>Vendor:</strong> A third-party entity or individual that
              registers on the Company’s platform as a result of the Partner’s
              referral.
            </li>
            <li>
              <strong>Ticket Sale:</strong> A successful transaction where a
              ticket is purchased through the Company’s platform.
            </li>
            <li>
              <strong>Commission:</strong> The monetary reward earned by the
              Partner, equal to $0.10 per ticket sold by referred vendors.
            </li>
            <li>
              <strong>Program:</strong> The Company’s Partner Program as
              outlined in this Agreement.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">3. Eligibility</h2>
          <p>To participate in the Partner Program, applicants must:</p>
          <ul className="list-disc pl-6">
            <li>
              Be at least 18 years old or the age of majority in their
              jurisdiction.
            </li>
            <li>
              Submit accurate and complete information during the application
              process.
            </li>
            <li>
              Comply with all terms and conditions outlined in this Agreement.
            </li>
          </ul>
          <p>
            The Company reserves the right to reject any application at its sole
            discretion.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">4. Partner Obligations</h2>
          <p>The Partner agrees to:</p>
          <ul className="list-disc pl-6">
            <li>
              Refer vendors to the Company’s platform using ethical and lawful
              methods.
            </li>
            <li>
              Avoid any fraudulent, misleading, or unethical practices in
              connection with the Partner Program. Fraudulent activity includes, but is not limited to, submitting false vendor referrals, misrepresentation of vendor activities, or attempts to manipulate commission calculations. The Company reserves the right to investigate and withhold or reverse commissions in cases of suspected fraud. Repeated fraudulent activity
              may result in immediate termination of this Agreement and forfeiture of unpaid commissions.
            </li>
            <li>
              Provide accurate and timely information to the Company as
              required.
            </li>
            <li>
              Ensure that any promotional materials or activities comply with
              this Agreement and the Company’s guidelines.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            5. Vendor Onboarding Criteria
          </h2>
          <p>A vendor will be considered a successful referral if:</p>
          <ul className="list-disc pl-6">
            <li>
              The vendor registers on the Company’s platform using the Partner’s
              referral link or is otherwise clearly identified as referred by
              the Partner.
            </li>
            <li>
              The vendor actively generates ticket sales through the Company’s
              platform.
            </li>
            <li>
              The vendor complies with the Company’s terms of service and
              policies.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            6. Commission Structure
          </h2>
          <ul className="list-disc pl-6">
            <li>
              The Partner will earn $0.10 for every paid ticket <strong>sold</strong>{" "}
              by vendors referred by the Partner.
            </li>
            <li>
              Free or refunded tickets will not count towards the $0.10 per ticket
              commission.
            </li>
            <li>
              Commissions will be calculated monthly based on completed events and paid out within 30 days
              after the end of each month.
            </li>
            <li>
              Commissions will only be paid if the total amount due exceeds $10
              in a given month. Any unpaid balances below $10 will roll over to the following month until the threshold is met. 
              Balances below $10 for over 12 months will be forfeited unless otherwise agreed in writing.
            </li>
            <li>Payments will be made via bank transfer.</li>
            <li>
              The Company reserves the right to withhold or reverse commissions
              in cases of fraud, chargebacks, or other violations of this
              Agreement.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            7. Duration and Termination
          </h2>
          <ul className="list-disc pl-6">
            <li>
              This Agreement will remain in effect until terminated by either
              party.
            </li>
            <li>
              Either party may terminate this Agreement with 30 days written
              notice.
            </li>
            <li>
              The Company may terminate the Agreement immediately for cause,
              including breach of terms or fraudulent activity.
            </li>
            <li>
              Upon termination, unpaid commissions will be forfeited if the
              termination is due to the Partner’s breach.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">8. Confidentiality</h2>
          <p>
            The Partner agrees to keep all non-public information about the
            Company, its platform, and other partners confidential and not
            disclose such information to any third party without the Company’s
            written consent.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            9. Intellectual Property
          </h2>
          <p>
            The Company grants the Partner a limited, non-exclusive, revocable
            license to use its trademarks, logos, and promotional materials
            solely for the purpose of promoting the Partner Program. The Partner
            may not modify or create derivative works of the Company’s
            intellectual property. Unauthorized or improper use of the Company’s intellectual property, including trademarks, logos, and promotional materials, will result in
            immediate termination of this Agreement and may subject the Partner to legal action for damages.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            10. Liability and Indemnification
          </h2>
          <ul className="list-disc pl-6">
            <li>
              The Company will not be liable for any damages arising from the
              Partner’s participation in the Program.
            </li>
            <li>
              The Partner agrees to indemnify and hold harmless the Company from
              any claims, damages, or liabilities arising from the Partner’s
              activities under this Agreement.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">11. Dispute Resolution</h2>
          <p>
            Any disputes arising under this Agreement will be resolved through
            mediation, followed by binding arbitration if mediation fails,
            governed by the laws of the State of Florida. Each party will bear its own costs associated with mediation
             and arbitration. However, if the Partner is found to have acted in bad faith or violated this Agreement,
             the Partner shall bear all associated costs, including the Company’s reasonable legal fees and expenses.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">12. Modifications</h2>
          <p>
            The Company reserves the right to modify the terms of this Agreement
            at any time. The Partner will be notified of any changes via email
            or through the Company’s platform. Continued participation in the
            Program constitutes acceptance of the modified terms.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">13. Acceptance</h2>
          <p>
            By submitting the application to join the Partner Program, the
            Partner acknowledges and agrees to the terms of this Agreement. No
            further action is required for acceptance.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            14. Compliance with Laws
          </h2>
          <p>
            The Partner agrees to comply with all applicable laws, regulations,
            and tax requirements in connection with their participation in the
            Program. The Partner is solely responsible for reporting and paying any taxes on commissions earned through the Program, including income taxes and any other applicable taxes.
            The Company will provide necessary tax documents (e.g., Form 1099-NEC for U.S. partners) as required by law.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">15. Entire Agreement</h2>
          <p>
            This Agreement constitutes the entire understanding between the
            Company and the Partner regarding the Partner Program and supersedes
            all prior agreements and understandings.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">16. Force Majeure</h2>
          <p>
          The Company shall not be liable for any delay or failure to perform its obligations under this Agreement due to causes beyond its reasonable control, including but not limited to acts
          of God, war, terrorism, pandemics, governmental action, or natural disasters.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Contact Information</h2>
          <p>
            For questions or concerns regarding this Agreement or the Partner
            Program, please contact{" "}
            <a
              href="mailto:contact@gethappyticketing.com"
              className="text-blue-500 underline"
            >
              contact@gethappyticketing.com
            </a>
            .
          </p>
        </section>
      </div>
    </>
  );
};
