import { Button } from "components/buttons";
import { InputOption, SelectInput, Toggler } from "components/forms";
import { Container, Modal } from "components/layout";
import { Navbar } from "components/navigation/Navbar";
import { useAuth } from "providers/AuthContext";
import { groupBy, keys, orderBy, set, sum } from "lodash";
import {
  InformationCircleIcon,
  TicketIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { PaymentCard } from "types/PaymentCard";
import { makeApiRequest, RequestProps } from "utils/api";
import { formatCurrency } from "utils/currency";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "components/loaders/Spinner";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { OrderItem } from "types/Order";
import poweredByStripe from "../../images/powered-by-stripe-black.svg";
import { useQuery } from "@tanstack/react-query";
import { useNotification } from "providers/NotificationContext";
import { is } from "date-fns/locale";
import { EventHeader } from "pages/Events/EventHeader";
import { Tooltip } from "components/Tooltip";
type OrderParams = {
  customer_zip?: string | null | undefined;
  save_card?: boolean;
  subtotal: number;
  happy_fee?: number;
  // event_id: number
  agreed_to_terms?: boolean;
  payment_method_id?: string;
  card_token?: string;
};

export const Checkout = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [saveCard, setSaveCard] = useState<boolean>(false);
  const [deletingItem, setDeletingItem] = useState<OrderItem | null>(null);
  const [openingTerms, setOpeningTerms] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    InputOption | undefined
  >();
  const [showCardForm, setShowCardForm] = useState<boolean>(false);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const { user, setCurrentUser } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { showError, showSuccess } = useNotification();
  // ALL TOTALS ARE IN CENTS, AND LATER FORMATTED WITH UTIL FUNCTION
  // get all cart values from API
  const items = user?.cart.items;
  const cartSubtotal = user?.cart.subtotal || 0;
  const processingFee = user?.cart.fees.processing || 0;
  const happyFee = user?.cart.fees.happy || 0;
  const handleToggleCardSave = (data: any) => {
    setSaveCard(data);
  };

  const handleOpenTerms = () => {
    setOpeningTerms(true);
  };

  const createFreeOrder = () => {
    setLoading(true);
    const order_params: OrderParams = {
      subtotal: cartSubtotal,
    };
    sendOrderRequest(order_params);
  };
  const createCardAndSubmitOrder = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      stripe
        .createToken(cardElement)
        .then((result) => {
          const token = result.token?.id;
          const order_params: OrderParams = {
            customer_zip: result.token?.card?.address_zip,
            save_card: saveCard,
            card_token: token,
            subtotal: cartSubtotal,
            happy_fee: happyFee,
          };

          sendOrderRequest(order_params);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const submitOrderWithExistingCard = () => {
    setLoading(true);
    const order_params: OrderParams = {
      // event_id: event.id || 0,
      payment_method_id: selectedPaymentMethod?.value,
      subtotal: cartSubtotal,
      happy_fee: happyFee,
      agreed_to_terms: true,
    };

    sendOrderRequest(order_params);
  };

  const sendOrderRequest = (order_params: OrderParams) => {
    const promoter_code = localStorage.getItem("promoter_code");
    const orderRequestParams: RequestProps = {
      path: "/orders",
      method: "POST",
      params: {
        user_id: user?.id,
        order_params: { ...order_params, promoter_code },
      },
    };
    makeApiRequest(orderRequestParams)
      .then((res) => {
        if (res.status === 201) {
          setLoading(false);
          setCurrentUser(res.data);
          localStorage.removeItem("promoter_code");
          navigate("/confirmation");
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = err.response
          ? err.response.data.message
          : "We were unable to process your order. Please try again, or contact support.";
        return showError(errorMessage);
      });
  };

  const deleteCartItem = (item: OrderItem | null) => {
    const eventSlug = user?.cart.event?.slug;
    if (!item) {
      return null;
    }
    const requestProps: RequestProps = {
      path: `/cart_items/${item.id}`,
      method: "DELETE",
    };

    makeApiRequest(requestProps).then((res) => {
      const user = res.data;
      setCurrentUser(user);
      setDeletingItem(null);
      if (!user?.cart.event) {
        navigate(`/events/${eventSlug}`);
      }
    });
  };

  const paymentOptions: InputOption[] = useMemo(() => {
    return (
      orderBy(user?.payment_cards, "is_default", "desc").map(
        (card: PaymentCard) => ({
          name: card.last_4,
          value: card.stripe_id,
          display: `${card.card_type} **** ${card.last_4}`,
        })
      ) || []
    );
  }, [user?.payment_cards]);

  useEffect(() => {
    setSelectedPaymentMethod(paymentOptions[0]);
  }, [paymentOptions]);

  const { data: taxData } = useQuery({
    queryKey: ["tax_estimate", user],
    queryFn: () =>
      makeApiRequest({
        path: "/tax_estimate",
      }),
    enabled: !!user && !!cartSubtotal,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const tax = taxData?.data;

  useEffect(() => {
    if (user && !user?.cart.event) {
      navigate("/explore");
      return;
    }
  }, []);
  const taxAmount = tax?.tax_estimate || 0;
  const total = cartSubtotal + happyFee + processingFee + taxAmount;
  const isFreeEvent = total === 0;
  const event = user?.cart.event;
  if (!event) {
    return null;
  }

  return (
    <>
      <Navbar />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="w-full min-h-56 relative sm:min-h-72 lg:min-h-80 overflow-hidden bg-gray-50 border-b border-gray-200">
          <EventHeader showRefund={() => setOpeningTerms(true)} event={event} showFullDescription={() => {}} />
        </div>
        <Container className="mt-5 pb-40">
          <div className="my-5">
            <Link
              className="flex items-center w-36"
              style={{
                color: event.organization.secondary_color
                  ? event.organization.secondary_color
                  : "#F72585",
              }}
              to={
                event.vendor_form_id
                  ? `/events/${event.id}/form`
                  : `/events/${event.slug}`
              }
            >
              <ChevronDoubleLeftIcon className="w-4" />{" "}
              {event.vendor_form_id ? "Back" : "Back to Event"}
            </Link>
          </div>

          <div className="flex flex-col sm:justify-between sm:flex-row-reverse">
            <div className="bg-gray-50 h-fit border-gray-200 border rounded-xl shadow-sm p-3 sm:w-[45%]">
              <h3 
                className="text-lg font-extrabold"
                style={{color: event.organization.primary_color ? event.organization.primary_color : '#7208B7'  }}
              >
                Order Summary
              </h3>

              <div className="my-4">
                <span className="font-semibold italic">
                  {event.name} - {user?.cart.event_start}
                </span>
                {user?.cart.items?.map((item: OrderItem) => {
                  const ticketInfo = item.ticket_info;
                  const priceLevelInfo = item.price_level_info;
                  return (
                    <div className="flex justify-between items-center mt-2">
                      <div>
                        <span className="flex items-center">
                          {priceLevelInfo.name} x 1&nbsp;
                          <Button
                            onClick={() => setDeletingItem(item)}
                            variant="link"
                          >
                            <TrashIcon className="w-4" />
                          </Button>
                        </span>
                        <span className="block text-sm text-gray-500">
                          {ticketInfo.tier} - {ticketInfo.section}
                        </span>
                      </div>
                      <span>{formatCurrency(item.price_cents)}</span>
                    </div>
                  );
                })}
              </div>
              {!isFreeEvent && (
                <>
                  <div className="flex justify-between pt-4 font-extrabold border-t border-gray-300">
                    <span>Subtotal</span>
                    <span>{formatCurrency(cartSubtotal)}</span>
                  </div>
                  <div className="flex justify-between font-extrabold">
                    <span>
                      CC Processing Fee
                      <Tooltip content="2.9% + $0.30">
                        <span className="text-sm font-medium mb-2">
                          <InformationCircleIcon className="h-4 w-4 inline-block ml-1 " />
                        </span>
                      </Tooltip>
                    </span>
                    <span>{formatCurrency(processingFee)}</span>
                  </div>
                  <div className="flex justify-between font-extrabold">
                    <span>
                      Happy Fee
                      <Tooltip content="Happy Ticketing charges $1.50 per ticket">
                        <span className="text-sm font-medium mb-2">
                          <InformationCircleIcon className="h-4 w-4 inline-block ml-1 " />
                        </span>
                      </Tooltip>
                    </span>
                    <span>{formatCurrency(happyFee)}</span>
                  </div>
                  {user.cart.add_tax_at_checkout && (
                    <div className="flex justify-between font-extrabold">
                      <span>Taxes</span>
                      <span>{formatCurrency(taxAmount)}</span>
                    </div>
                  )}
                </>
              )}
              <div className="flex justify-between pt-3 text-lg font-extrabold">
                <span>Total</span>
                <span>{formatCurrency(total)}</span>
              </div>
              {isFreeEvent ? (
                <Button
                  disabled={loading}
                  onClick={() => createFreeOrder()}
                  variant="primary"
                  className="w-full my-10 flex justify-center"
                >
                  {loading ? <Spinner /> : "Complete Order"}
                </Button>
              ) : (
                <div className="flex justify-center my-6">
                  <img
                    src={poweredByStripe}
                    alt="powered by stripe"
                    className="w-20 h-auto"
                  />
                </div>
              )}
            </div>
            <div className="mt-6 sm:w-[45%] sm:mt-0 sm:mb-4">
              {!isFreeEvent && (
                <>
                  <div className="border-gray-300 py-2"></div>
                  <h3 
                    className=" font-extrabold text-xl"
                    style={{color: event.organization.primary_color ? event.organization.primary_color : '#7208B7'  }}
                  >
                    Payment Details
                  </h3>

                  <div className="py-2">
                    <span className="font-extrabold">Customer:&nbsp;</span>
                    <span>
                      {user?.first_name} {user?.last_name}
                    </span>
                  </div>
                  {user?.payment_cards?.length && !showCardForm ? (
                    <div className="py-2">
                      <div className="flex justify-between">
                        <span className="font-extrabold">Saved Cards</span>
                        <Button
                          variant="link"
                          onClick={() => setShowCardForm(true)}
                        >
                          Use different card
                        </Button>
                      </div>
                      <div className="my-3">
                        <SelectInput
                          selectedOption={selectedPaymentMethod}
                          onSelect={(option) =>
                            setSelectedPaymentMethod(option)
                          }
                          options={paymentOptions}
                        />
                      </div>

                      <div className="flex items-center my-3">
                        <input
                          type="checkbox"
                          id="agreed_to_terms"
                          className="mr-2"
                          checked={agreedToTerms}
                          onChange={(e) => setAgreedToTerms(e.target.checked)}
                        />
                        <label htmlFor="agreed_to_terms" className="text-sm">
                          I agree to the
                          <Button
                            onClick={() => setOpeningTerms(true)}
                            variant="link"
                            style={{
                              marginLeft: 3, marginRight: 3,
                              color: event.organization.secondary_color
                                ? event.organization.secondary_color
                                : '#F72585',
                            }}
                          >
                            Refund Policy, Event Terms,
                          </Button>
                          and
                          <Link
                            to={"/terms-of-service"}
                            className="leading-6 text-pink font-bold ml-1"
                            target="_blank"
                          >
                            Happy Terms of Service
                          </Link>
                        </label>
                      </div>
                      <Button
                        disabled={loading || !agreedToTerms}
                        onClick={submitOrderWithExistingCard}
                        className="relative h-14 w-full mt-4"
                        variant="primary"
                      >
                        Place Order
                        {loading && (
                          <Spinner className="absolute right-4 top-0 bottom-0 m-auto dark:text-purple text-pink w-5 h-5" />
                        )}
                      </Button>
                    </div>
                  ) : null}
                  {(showCardForm || !user?.payment_cards?.length) && (
                    <div className="py-2">
                      <div className="flex justify-between mb-3">
                        <span className="font-extrabold block">Card Info</span>
                        <Button
                          className={
                            !user?.payment_cards?.length ? "hidden" : ""
                          }
                          variant="link"
                          onClick={() => setShowCardForm(false)}
                        >
                          Use existing card
                        </Button>
                      </div>
                      <CheckoutForm
                        loading={loading}
                        organization={event.organization}
                        handleToggleCardSave={handleToggleCardSave}
                        handleSubmit={createCardAndSubmitOrder}
                        handleOpenTerms={handleOpenTerms}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Container>
      </motion.div>
      <Modal
        onRequestClose={() => {
          setDeletingItem(null)
          setOpeningTerms(false)
        }}
        isOpen={!!deletingItem || !!openingTerms}
      >
        {deletingItem && (
          <div className="flex flex-col items-center">
            <div className="py-4 font-bold">
              Are you sure you want to delete this from your cart?
            </div>

            <Button
              onClick={() => deleteCartItem(deletingItem)}
              variant="pink"
              className="rounded-lg"
            >
              Confirm & Remove from Cart
            </Button>

            <span
              onClick={() => setDeletingItem(null)}
              className="mt-5 text-blue"
              role="button"
            >
              Cancel
            </span>
          </div>
        )}

        {openingTerms && (
          <div className="flex flex-col items-center">
            <div className="py-4">
              <b>Refund Policy</b>
              <p>
                Happy Ticketing will not offer any refunds for processing fees
                or Happy Ticketing's Fees. Refunds for the base price of the
                ticket are at the event's discretion.
                {user.cart.event.refund_policy}
              </p>
              <br />
              <b>Event Terms and Conditions</b>
              <p>
                By placing this order, you agree to the terms and conditions of
                the event and the Happy Ticketing platform.
                {user.cart.event.additional_terms}
              </p>
            </div>

            <span
              onClick={() => setOpeningTerms(false)}
              className="mt-5 text-blue"
              role="button"
            >
              Close
            </span>
          </div>
        )}
      </Modal>
    </>
  );
};

export const CheckoutForm = ({
  loading,
  handleSubmit,
  handleToggleCardSave,
  handleOpenTerms,
  organization
}: {
  loading?: boolean;
  handleToggleCardSave: (data: any) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleOpenTerms: () => void;
  organization: { primary_color?: string; secondary_color?: string },
}) => {
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const primaryColor = organization.primary_color || "#7208B7"; // Default fallback color
  const disabledColor = "#cccccc"; // Light gray for disabled state
  const loadingColor = "#9e9e9e"; // A different gray for loading state

  const getButtonStyles = () => {
    if (loading || !agreedToTerms) {
      return {
        backgroundColor: disabledColor, // Use disabled color
        color: "#ffffff",
        cursor: "not-allowed",
      };
    }
    return {
      backgroundColor: primaryColor,
      color: "#ffffff",
    };
  };
  return (
    <form onSubmit={handleSubmit}>
      <CardElement className="h-10 flex flex-col justify-center px-4 w-full border border-gray-400 shadow-sm" />
      <div className="py-4">
        <Toggler
          onToggle={handleToggleCardSave}
          label="Save card for future use"
        />
      </div>
      <div className="flex items-center my-3">
        <input
          type="checkbox"
          data-testid="tos"
          id="agreed_to_terms"
          className="mr-2"
          checked={agreedToTerms}
          onChange={(e) => setAgreedToTerms(e.target.checked)}
        />
        <label htmlFor="agreed_to_terms" className="text-sm">
          I agree to the
          <Button
            onClick={handleOpenTerms}
            variant="link"
            type="button"
            style={{
              marginLeft: 3, marginRight: 3,
              color: organization.secondary_color
                ? organization.secondary_color
                : '#F72585',
            }}
          >
            Refund Policy, Event Terms,
          </Button>
          and
          <Link
            to={"/terms-of-service"}
            className="leading-6 font-bold ml-1"
            target="_blank"
            style={{
              color: organization.secondary_color
                ? organization.secondary_color
                : '#F72585',
            }}
          >
            Happy Terms of Service
          </Link>
        </label>
      </div>
      <Button
        disabled={loading || !agreedToTerms}
        type="submit"
        className="h-14 w-full mt-4 relative"
        style={getButtonStyles()}
      >
        Place Order
        {loading && agreedToTerms && (
          <Spinner className="absolute right-4 top-0 bottom-0 m-auto dark:text-purple text-pink w-5 h-5" />
        )}
      </Button>
    </form>
  );
};
