import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "providers/AuthContext";
import { useNotification } from "providers/NotificationContext";
import { Organization } from "types/Organization";
import { PartnerDetails } from "types/PartnerDetails";
import { makeApiRequest } from "utils/api";
import { formatCurrency } from "utils/currency";
import { formatInt } from "utils/string";

export const PartnerDashboard = () => {
  const { user } = useAuth();
  const {showSuccess} = useNotification();

  const {data: partnerDetails} = useQuery({
    queryKey: ['partnerOrgs', user?.partner_code],
    queryFn: async () => {
      const response = await makeApiRequest({
        path: '/partner_details',
      })
      return response.data;
    },
    enabled: !!user?.partner
  })

  const {stats, orgs}: PartnerDetails = partnerDetails || {};

  const callouts = [
    {
      name: "Total Earned",
      stat: formatCurrency(stats?.total_revenue),
      previousStat: "$3,946",
      change: "12%",
      changeType: "increase",
    },
    {
      name: "Vendors",
      stat: formatInt(stats?.org_count),
      previousStat: "56",
      change: "2.02%",
      changeType: "increase",
    },
    {
      name: "Tickets Sold",
      stat: formatInt(stats?.tickets_sold),
      previousStat: "28",
      change: "4.05%",
      changeType: "decrease",
    },
  ];

  const copyReferralLinkToClipboard = () => {
    navigator.clipboard.writeText(
      `https://www.gethappyticketing.com/get-started?partner_code=${user?.partner_code}`
    )
    .then(() => {
      showSuccess('Referral link copied to clipboard');
    })
  }


  return (
    <div className="flex flex-col gap-y-6 w-full">
      <div>
        <h2 className="text-xl font-bold">
          Welcome, {user?.first_name}
        </h2>
        <button onClick={copyReferralLinkToClipboard} className="flex items-center gap-1 text-xs text-gray-400 mt-1">
          <DocumentDuplicateIcon className="w-3 h-3" />
          <span>Copy Referral Link</span>
        </button>
      </div>
      <div className="w-full">
        <h3 className="text-base font-semibold">Last 30 days</h3>
        <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
          {callouts.map((item) => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal">
                {item.name}
              </dt>
              <dd className="mt-1 flex items-baseline justify-between md:block sm:flex-col">
                <div className="flex items-baseline text-2xl font-semibold text-blue">
                  {item.stat}
                </div>

                {/* <div
                  className={classNames(
                    item.changeType === "increase"
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800",
                    "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                  )}
                >
                  {item.changeType === "increase" ? (
                    <ArrowUpIcon
                      aria-hidden="true"
                      className="-ml-1 mr-0.5 size-5 shrink-0 self-center text-green-500"
                    />
                  ) : (
                    <ArrowDownIcon
                      aria-hidden="true"
                      className="-ml-1 mr-0.5 size-5 shrink-0 self-center text-red-500"
                    />
                  )}

                  <span className="sr-only">
                    {" "}
                    {item.changeType === "increase"
                      ? "Increased"
                      : "Decreased"}{" "}
                    by{" "}
                  </span>
                  {item.change}
                </div> */}
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="w-full rounded-lg bg-white shadow p-5">
        <h3 className="text-base font-semibold">Your Vendors</h3>
        {
          orgs && orgs.length > 0 ? (
            <OrgList orgs={orgs} />
          ) : (
            <>
              <p className="text-center mt-5">No vendors yet</p>

              <div className="mt-5 flex items-center justify-between">
                <p className="text-xs text-gray-400">Refer a vendor to start earning</p>
                <button onClick={copyReferralLinkToClipboard} className="flex items-center gap-1 text-xs text-gray-400">
                  <DocumentDuplicateIcon className="w-3 h-3" />
                  <span>Copy Referral Link</span>
                </button>
              </div>
            </>
          )
        }
      
      </div>
    </div>
  );
};

export const OrgList = ({orgs}: {orgs: Organization[]}) => {
  if (!orgs) {
    return <p>No organizations found</p>
  }
  return (
    <ul role="list" className="divide-y divide-gray-200">
      {orgs.map((org) => (
        <li key={org.id} className="flex justify-between gap-x-4 py-3">
          <div className="min-w-0">
            <p className="text-sm/6 font-semibold">
              {org.name}
            </p>
            <p className="mt-1 truncate text-xs/5 text-gray-500">
              {org.contact_email}
            </p>
          </div>
          {/* <div className='flex flex-col items-end'>
            <span className='font-bold text-sm'>
              Tickets Sold
            </span>
            <span className='text-sm text-green-500 mt-1 font-bold'>
              24
            </span>
          </div> */}
        </li>
      ))}
    </ul>
  );
};
